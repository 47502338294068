import React, { useState, useEffect } from 'react';
import axios from 'axios';
import '@telegram-apps/telegram-ui/dist/styles.css';
import { AppRoot, List, Chip, Avatar, Image, Input } from '@telegram-apps/telegram-ui';
import { MyTabbar } from './components/MyTabbar'; // Assuming you have this component
import NewtonLogo from '../src/images/apologonobg.png'; // Assuming your logo path is correct
import LoadingPage from './components/LoadingPage'; // Assuming you have this component
import { retrieveLaunchParams } from '@telegram-apps/sdk';
import { useUser } from './UserContext'; // Adjust path if necessary

export let authtoken = null; // Initialize with a default value
// export let userRefToken = null; // Initialize with a default value
// export let userRefData = null;
// export let userData = null;


export const App = () => {
    const { initData, initDataRaw } = retrieveLaunchParams();
    
    console.log(initDataRaw);
    const [result, setResult] = useState('');
    const { userData, setUserData } = useUser(); // Get userData and setUserData from context

    useEffect(() => {
        // test api
        axios.post('https://lets-connect.org/api/auth/login', {
            "initData": initDataRaw,
            "referralToken": "",
        }, {
            headers: {
                "Content-Type": "application/json",
                "accept":"application/json"
            }
        }).then((res) => {
          //  console.log(res.data);
            setResult(res.data);
        })
    }, [initDataRaw]);

    const [user, setUser] = useState(null); // Telegram user data
   // const [userData, setUserData] = useState(null); // to use context instead 
    const [loading, setLoading] = useState(true); // Loading state

    authtoken = result?.access_token;
    console.log(authtoken);

    // useEffect(() => {
    //     axios.get('https://lets-connect.org/api/user/me', {
    //     }, {
    //         headers: {
    //             'accept':'application/json',
    //             'Authorization': `Bearer ${authtoken}`,
    //             'Cache-Control': 'no-cache',
    //             'Connection': 'keep-alive',
    //             'Content-Type': 'application/json',


    //         }
    //     }).then((res) => {
    //         console.log(res);
    //         setUserData(res);

    //     })
    // }, [authtoken]);

    useEffect(() => {
        if (authtoken) {
            fetch('https://lets-connect.org/api/user/me', {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'accept': '*/*',
                    'Cache-Control': 'no-cache',
                    'Connection': 'keep-alive',
                    'Authorization': `Bearer ${authtoken}`
                },
            })
                .then(response => response.json())
                .then(data => {
                    setUserData(data); // Update the context with userData
                });
        }
    }, [authtoken, setUserData]);


    // useEffect(() => {
    //     fetch('https://lets-connect.org/api/user/me', {
    //         method: 'GET',
    //         headers: {
                
    //             'Content-Type': 'application/json', // 
    //             'accept': '*/*', // 
    //             'Cache-Control': 'no-cache',
    //             'Connection': 'keep-alive',
    //             'Authorization': `Bearer ${authtoken}`  
    //         },
    //     })
    //         .then(response => {
    //             // if (!response.ok) {
    //             //     throw new Error(`HTTP error! Status: ${response.status}`);
    //             // }
    //             return response.json();
    //         }) 
    //         .then(data => {
    //             setUserData(data);
    //             userRefToken = data?.referralToken;

    //         })
    //        // .catch(error => console.error('Error:', error));
    // }, [authtoken]);

//      console.log(userData);
// exporting some user data










    useEffect(() => {
        const tg = window.Telegram.WebApp;

        // Initialize Telegram WebApp
        tg.ready();

        // Fetch and set user data
        const telegramUser = tg.initDataUnsafe?.user || null;
        setUser(telegramUser);

        // // Fetch user token balance from backend
        // const fetchUser = async () => {
        //     try {
        //         const response = await axios.get('https://lets-connect.org/api/user/me/', {
        //             userId: telegramUser?.id,
        //         });
        //         setUserData(response);
        //     } catch (error) {
        //         console.error('Error fetching tokens:', error);
        //     } finally {
        //         setLoading(false); // Stop loading spinner
        //     }
        // };

        // if (telegramUser) {
        //     fetchUser();
        // } else {
        //     setLoading(false); // No user data, stop loading
        // }

        // Set theme colors dynamically
        const { secondary_bg_color } = tg.themeParams || {};
        if (secondary_bg_color) {
            document.body.style.backgroundColor = secondary_bg_color;
        }
    }, []);

    // if (loading) {
    //     return <AppRoot><LoadingPage /></AppRoot>;
    // }

    return (
        <AppRoot>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                height: '100vh', // Full viewport height
                overflow: 'hidden', // Prevent overall scrolling
            }}>
                {/* Fixed Header */}
                <List style={{
                    background: 'var(--tgui--secondary_bg_color)',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexShrink: 0, // Prevent shrinking
                }}>

                    <div style={{
                        display: 'flex',
                        gap: 25,
                        alignItems: 'center',
                        backgroundColor: 'var(--tgui--primary_bg_color)'
                    }}>

                        <Chip mode="elevated" before={<Avatar size={20} />}>
                            {user?.first_name || 'Guest'}
                        </Chip>
                        <Image
                            size={64}
                            src={NewtonLogo}
                            alt="App logo"
                        />
                        <Chip mode="elevated" before={<Avatar size={20} />}>
                            {userData?.tokens}
                        </Chip>
                    </div>
                </List>

                {/* Tabbar Navigation */}
                <MyTabbar />
            </div>
        </AppRoot>
    );

};



export default App;
