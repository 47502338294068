import styles from './FormSection.module.css';
import { Button, Input, Section } from '@telegram-apps/telegram-ui';
import { Copy } from 'lucide-react';
//import { userRefToken } from '../../App.js';
import { useUser } from '../../UserContext'; // Adjust path if necessary

import React, { useState } from "react";




export const MyInvite = () => {
  const [copySuccess, setCopySuccess] = useState("");
  const [copySuccess2, setCopySuccess2] = useState("");

  const { userData } = useUser(); // Access userData from context

  const refLink = `t.me/NEWTON_DeSci_Bot?start=${userData.referralToken}`;

  const handleCopy = (textToCopy) => {
    if (textToCopy.trim() === "") {
      setCopySuccess("Nothing to copy!");
      return;
    }

    navigator.clipboard.writeText(textToCopy)

      .then(() => {
        setCopySuccess("Copied!");
        setTimeout(() => {
          setCopySuccess("");
        }, 1250);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopySuccess("Failed to copy!");
      });

  };

  const handleCopy2 = (textToCopy) => {
    if (textToCopy.trim() === "") {
      setCopySuccess2("Nothing to copy!");
      return;
    }

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        setCopySuccess2("Copied!");
        setTimeout(() => {
          setCopySuccess2("");
        }, 1250);
      })
      .catch((err) => {
        console.error("Failed to copy: ", err);
        setCopySuccess2("Failed to copy!");
      });
  };


 

  return (
    <Section footer="Share the link with your Frens! (Or the code if necessary)">
      <Input
        value={refLink}
        after={
          <Button
          onClick={() => handleCopy(refLink)}
          before={<Copy />}
            size="s"
          > 
            {copySuccess}
          </Button>
        }
        readOnly
        header="Your invite link"
      />
      <Input
        value={userData.referralToken}
        after={
          <Button
            onClick={() => handleCopy2(userData.referralToken)}
            before={<Copy />}
            size="s"
          >
            {copySuccess2}
          </Button>
        }
        readOnly
        header="Your invite Code"
      />
    </Section>
  );
};
