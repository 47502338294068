import React, { createContext, useContext, useState } from 'react';

// Create the UserContext
const UserContext = createContext();

// Export a custom hook for easy access
export const useUser = () => useContext(UserContext);

// Create the provider
export const UserProvider = ({ children }) => {
    const [userData, setUserData] = useState(null);
    const [userRefData, setUserRefData] = useState(null);

    return (
        <UserContext.Provider value={{ userData, setUserData , userRefData, setUserRefData}}>
            {children}
        </UserContext.Provider>
    );
};
