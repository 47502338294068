import React from 'react';
import { SegmentedControl,Cell,Spoiler } from '@telegram-apps/telegram-ui';
function LearnSection() {
  return (
    <div >
        <SegmentedControl>
  <SegmentedControl.Item
    disabled
  >
    Courses
  </SegmentedControl.Item>
  <SegmentedControl.Item
    disabled
  >
    Events
  </SegmentedControl.Item>
 
</SegmentedControl>
<Spoiler>
<Cell >
      Courses and events are on the way! <br/> For paid ones, you can pay with $Eureka token.
    </Cell>

  </Spoiler>
    </div>
  )
}

export default LearnSection
